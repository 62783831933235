import './App.css';
import backgroundImage from './background.png';
import myImage from './logo.png';

function App() {
  return (
    <div className="App" style={{backgroundImage: `url(${backgroundImage})`}}>
      <header className="App-header">
      <img src={myImage} alt="Logo" />
        <h1>
          Coming soon
        </h1>
        <a
          className="App-link"
          href="https://www.instagram.com/milicc_aleksa/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Aleksa Milic
        </a>
      </header>
    </div>
  );
}

export default App;
